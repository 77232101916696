import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import renderPageText from '../Components/RenderPageTexts';
import VerifyUser from './VerifyUser';
import APIEnv from "./APIEnv";

export default function NewHeader() {

    const logOut = () => {
        document.getElementsByClassName("opacity")[0].style.display = "block"
        document.getElementsByClassName("logOutWindow")[0].style.display = "block"
    }


    const [showPop, setPop] = useState(false);
    const foreningClose = () => {
        if(768 <= window.innerWidth){
            setPop(false);
        }
    }
    const foreningOpen = () => {
        if(768 <= window.innerWidth){
            setPop(true);
        }
    }
    const foreningToggle = () => {

        if (window.innerWidth <= 768) {
            setPop(!showPop);
        }
    }

    const [showMenu, setShowMenu] = useState(false);
    const mainMenuClose = () => {
        setShowMenu(false)
    }
    const toggleMenu = () => {
        setShowMenu(!showMenu)
    };

    const [showStateLogin, setLogin] = useState(false);
    const loginOpen = () => {
        setLogin(true)
    }
    const loginClose = () => {
        setLogin(false)
    }


    const showLogin = () => {
        document.getElementsByClassName("opacity")[0].style.display = "block"
        document.getElementsByClassName("loginWindow")[0].style.display = "block"
    }
    const createAccountOpen = () => {
        document.getElementsByClassName("createAccount")[0].style.opacity = "1";
        document.getElementsByClassName("createAccount")[0].style.pointerEvents = "unset";
    };

    const [showEdit, setEdit] = useState(false);

    useEffect(() => {
        const titleElement = document.getElementsByClassName("newTitle");
        const textElement = document.getElementsByClassName("newText");
        const editMenu = document.getElementsByClassName("editControlPanel")[0]
        let i = 0;
        while (true) {
            if (!titleElement[i] && !textElement[i]) {
                break;
            }
            titleElement[i]?.setAttribute("contentEditable", showEdit ? "true" : "false");
            textElement[i]?.setAttribute("contentEditable", showEdit ? "true" : "false");
            i++
        }

        if (showEdit) {
            editMenu.style.pointerEvents = "unset";
            editMenu.style.opacity = "1";
        } else {
            editMenu.style.opacity = "0";
            editMenu.style.pointerEvents = "none";
        }

    }, [showEdit]);



    useEffect(() => {
        const loginPop = document.getElementsByClassName("loginPop")[0]
        if (showStateLogin) {
            loginPop.style.height = "auto";
            loginPop.style.maxHeight = "200px";
        } else if (loginPop) {
            loginPop.style.maxHeight = "0"
        }
    }, [showStateLogin])

    useEffect(() => {
        const foreningPop = document.getElementsByClassName("foreningPop")[0];
        if (showPop) {
         
            foreningPop.style.height = "auto";
            foreningPop.style.maxHeight = "600px";
        } else {
     
            foreningPop.style.height = "0";
            foreningPop.style.maxHeight = "0";
        }
    }, [showPop])

    useEffect(() => {
        const dropDown = document.getElementsByClassName("newMenu")[0]
        const burgerContainer = document.querySelector('.burgerContainer');
        if (showMenu) {
            dropDown.style.maxHeight = "500px";
            dropDown.style.height = "auto";
            burgerContainer.classList.toggle('active');
        } else {
            dropDown.style.maxHeight = "0";
            burgerContainer.classList.remove('active');
        }
    }, [showMenu])

    const [originalText, setOriginalText] = useState([]);
    const [originalTitle, setOriginalTitle] = useState([]);
    useEffect(() => {
        setTimeout(() => {
            const textElements = document.getElementsByClassName("newText");
            const titleElements = document.getElementsByClassName("newTitle");
            let textArray = [];
            let titleArray = [];
            for (const text of textElements) {
                textArray.push({
                    text: text.innerHTML,
                    id: text.id
                })
            }
            for (const title of titleElements) {
                titleArray.push({
                    title: title.innerHTML,
                    id: title.id
                })
            }
            setOriginalText(textArray);
            setOriginalTitle(titleArray);
        }, 1000)
    }, []);

    const originalTextTitle = () => {
        const textElements = document.getElementsByClassName("newText");
        const titleElements = document.getElementsByClassName("newTitle");
        let i = 0;
        while (true) {
            if (!titleElements[i] && !textElements[i]) {
                break;
            }
            if (textElements[i]) {
                if (!originalText.find(obj => obj.id == textElements[i].id)?.text) {
                    window.location.reload();
                } else {
                    textElements[i].innerHTML = originalText.find(obj => obj.id == textElements[i].id)?.text;
                }
            }
            if (titleElements[i]) {
                if (!originalTitle.find(obj => obj.id == titleElements[i].id)?.title) {
                    window.location.reload();
                } else {
                    titleElements[i].innerHTML = originalTitle.find(obj => obj.id == titleElements[i].id)?.title;
                }
            }
            i++;
        };
    }

    const dbChangeContent = async (e) => {
        e.preventDefault();
        const newTitles = document.getElementsByClassName("newTitle");
        const newTexts = document.getElementsByClassName("newText");
        let newContentArray = [];
        let i = 0;
        while (true) {
            if (!newTitles[i] && !newTexts[i]) {
                break;
            }
            let contentTitleString = `
            <title>${newTitles[i]?.innerHTML}</title>
            <text>${newTexts[i]?.innerHTML}</text>`

            newContentArray.push({
                page: document.location.href,
                content: contentTitleString,
                section: i,
                id: newTexts[i] ? newTexts[i].id : newTitles[i].id
            });

            i++;
        };

        try {
            const res = await axios.post(`${APIEnv.apiUrl}/api/pagecontent/change`, {
                token: localStorage.getItem("bc7f398893fc"),
                PageContent: JSON.stringify(newContentArray),
            })

        } catch {
            console.log("Failed to update page content.")
        }
        setEdit(false)
    };

    const token = localStorage.getItem("bc7f398893fc");
    const isSmallScreen = window.innerWidth < 768;

    const [user, setUser] = useState([]);
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const res = await axios.get(`${APIEnv.apiUrl}/user/getuser?salt=${localStorage.getItem("bc7f398893fc")}`);
                setUser(res.data);
            } catch (e) {
                console.log(e)
            }
        };
        fetchUsers();
    }, []);

    return (
        <>

            <div className="menuContainer">
                <div className='row'>
                    {/*  logo */}
                    <div className="col-md-5 col-12 logoContainer">
                        <Link to="/"><img src='/images/ikoner/brf _strindbergshuset.png' /></Link>
                    </div>
                    <div className='col-xxl-7 col-md-7 newMenu'>

                        <div className="newChoice Link p-4 pt-0 pb-0"  onMouseLeave={foreningClose} onMouseEnter={foreningOpen} onClick={foreningToggle}>

                            Om föreningen

                        </div>

                        <div className='foreningPop p-0 m-0 mt-4'>
                            <div className="col-12"><Link style={{ textDecoration: "none" }} to="/about" className='Link' onClick={toggleMenu}>Om föreningen</Link></div>
                            <div className="col-12"><Link style={{ textDecoration: "none" }} to="/history" className='Link' onClick={toggleMenu}>Vår historia</Link></div>
                            <div className="col-12"><Link style={{ textDecoration: "none" }} to="/rules" className='Link' onClick={toggleMenu}>Regler</Link></div>
                            <div className="col-12"><Link style={{ textDecoration: "none" }} to="/spaces" className='Link' onClick={toggleMenu}>Gemensamma utrymmen</Link></div>
                            <div className="col-12"><Link style={{ textDecoration: "none" }} to="/renoveringar" className='Link' onClick={toggleMenu}>Renoveringar</Link></div>
                            <div className="col-12"><Link style={{ textDecoration: "none" }} to="/maklare" className='Link' onClick={toggleMenu}>Mäklarinformation</Link></div>
                            <div className="col-12"><Link style={{ textDecoration: "none" }} to="/stadgar" className='Link' onClick={toggleMenu}>Stadgar</Link></div>
                            <div className="col-12"><Link style={{ textDecoration: "none" }} to="/redovisningar" className='Link' onClick={toggleMenu}>Årsredovisningar</Link></div>
                            <div className="col-12"><Link style={{ textDecoration: "none" }} to="/meetings" className='Link' onClick={toggleMenu}>Årsstämma</Link></div>
                        </div>

                
                        <div className='Link newChoice p-4 pt-0 pb-0'><Link style={{ textDecoration: "none" }} to="/contact" className='Link' onClick={toggleMenu}>Kontakt</Link></div>
                        {token ? <div className='Link newChoice p-4 pt-0 pb-0'><Link style={{ textDecoration: "none" }} to="/forum" className='Link' onClick={toggleMenu}>Anslagstavlan</Link></div> : <></>}
                        {token ?
                            isSmallScreen ? <>
                                <div className='col-12 Link newChoice'><Link style={{ textDecoration: "none", color: "white" }} to="/profile" onClick={toggleMenu}>Profil</Link></div>
                                {user.isAdmin ? <>
                                    <div className='col-12 Link newChoice' onClick={() => { setEdit(true); toggleMenu() }}>Redigera sida</div>
                                </> : <></>}

                                <div className='col-12 Link newChoice' onClick={() => window.location.href = `https://pbforvaltning.realportal.nu`}>Bo-portalen</div>
                                <div className='col-12 pb-3 Link newChoice' onClick={() => { toggleMenu(); logOut(); }}>Logga ut</div>
                            </> : <>
                                <div className='Link newChoice' onMouseEnter={loginOpen} onMouseLeave={loginClose}>
                                    <i className='fa fa-user'> </i>
                                    <div className='loginPop'>
                                        <div className='col-12 pt-1 mt-3'><Link style={{ textDecoration: "none", color: "black" }} to="/profile">Profil</Link></div>
                                        {user.isAdmin ? <>
                                            <div className='col-12 pt-1 mt-3' onClick={() => { setEdit(true); toggleMenu() }}>Redigera sida</div>
                                        </> : <></>}
                                        

                                        <div className='col-12 pt-1 mt-3' onClick={() => window.location.href = `https://pbforvaltning.realportal.nu`}>Bo-portalen</div>
                                        <div className='col-12 pt-1 mt-3 pb-2' onClick={() => { toggleMenu(); logOut(); }}>Logga ut</div>
                                    </div>
                                </div>

                            </> : <>
                            {isSmallScreen ? <>
                                <div className='col-12 mt-md-4 mt-3 pt-1 pb-1 newChoice Link' onClick={() => { toggleMenu(); showLogin() }}> Logga in</div>
                                            <div className='col-12 mt-1 pt-1 pb-1 newChoice Link' onClick={() => { toggleMenu(); createAccountOpen() }} >Skapa konto</div>
                            </> : <>
                            <div className="newChoice" onMouseEnter={loginOpen} onMouseLeave={loginClose}>
                                    <div className="container Link p-4 pt-0 pb-0">
                                        Logga in
                                        <div className='loginPop p-0 m-0'>
                                            <div className='col-12 mt-md-4 mt-3 pt-1 pb-1' onClick={() => { toggleMenu(); showLogin() }}> Logga in</div>
                                            <div className='col-12 mt-1 pt-1 pb-1' onClick={() => { toggleMenu(); createAccountOpen() }} >Skapa konto</div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                            </>}
                    </div>
                </div>
            </div>

            {/*  burger */}
            <div className='burgerContainer' onClick={toggleMenu}>
                <div className='patty' id="patty1"></div>
                <div className='patty' id="patty2"></div>
                <div className='patty' id="patty3"></div>
                <p className='mt-2 text-center'></p>
            </div>

            {/* editText */}

            <div className='row justify-content-center'>
                <div className='editControlPanel col-lg-3 col-md-4 col-sm-4 col-10 pt-2'>
                    <form onSubmit={dbChangeContent}>
                        <div className='d-flex justify-content-around'>
                            <div className='cancelButton p-1' onClick={() => {
                                setEdit(false);
                                originalTextTitle();
                            }}>Avbryt</div>
                            <button type="submit">Spara ändringar</button>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}