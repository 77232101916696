import React, { useState, useEffect } from 'react';
import axios from 'axios';
import APIEnv from "./APIEnv";
export default function VerifyUser() {
    const [validToken, setValidToken] = useState(null);

    useEffect(() => {

        const checkToken = async () => {

            const storedToken = localStorage.getItem("bc7f398893fc");
            let attempts = 5;
            let current = 0;

            while (current < attempts) {

                try {
                    const res = await axios.post(`${APIEnv.apiUrl}/user/verify?salt=${storedToken}`);
                    if (res.status == 200) {
                        setValidToken(true);
                        return ;
                    }
                } catch (error) {
                    console.log("Failed to verify user.");
                    current++;
                }
            }

            setValidToken(false);
            return false;        
        };

        checkToken();
    }, []);
    return validToken;
}