import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FileUpload from './FileUpload';
import APIEnv from "./APIEnv";
export default function CreatePost() {

  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [selectedColor, setSelectedColor] = useState('#96321e');
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    user: localStorage.getItem("bc7f398893fc"),
    page: document.getElementById("href")?.getAttribute("path"),
    color: selectedColor,
    imagePath: ""
  });
  const [imageData, setImageData] = useState(null);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      page: document.getElementById("href")?.getAttribute("path")
    });
  };

  const postBlog = async (e) => {
    e.preventDefault();
    if(formData.title == "" || formData.content == "" || imageData == null){
      alert("Vänligen fyll i alla fält.")
    } else {
    let attempts = 5;
    let current = 0;
    while (attempts > current) {
      console.log(formData)
      
      try {
        const res = await axios.post(`${APIEnv.apiUrl}/api/blogs/newpost`, formData);
        document.getElementsByClassName("popUpContainer")[0].style.opacity = 1;
        document.getElementsByClassName("popUpContainer")[0].innerHTML = "Inlägget publicerat!";
        document.getElementsByClassName("createPostContainer")[0].style.pointerEvents = "none";
        document.getElementsByClassName("createPostContainer")[0].style.opacity = 0;
        setTimeout(() => {
          document.getElementsByClassName("popUpContainer")[0].style.opacity = 0;
        }, 2000);
        const blogPostId = res.data.id;

        const imageFormData = new FormData();
        imageFormData.append("file", imageData);
        imageFormData.append("blogPostId", blogPostId)
        console.log(imageFormData)
        try {
          const imageRes = await axios.post(`${APIEnv.apiUrl}/api/blogs/uploadimg`, imageFormData);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } catch (e){
          console.log("Error posting image", e)
        }
        console.log(res.data.id)
        break;
      } catch (error) {
        console.error('Error posting blog:', error);
        current++;
      }
    }
  }
  };


  const hidePost = (e) => {
    e.preventDefault();
    document.getElementsByClassName("createPostContainer")[0].style.opacity = 0;
    document.getElementsByClassName("createPostContainer")[0].style.pointerEvents = "none";
    document.getElementsByClassName("popUpContainer")[0].style.opacity = 0;
  }

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
    setFormData({
      ...formData,
      color: event.target.value
    })
  };

  const handleImageChange = (e) => {
    setUploadedFileName(e.target.files[0].name)
    setImageData(e.target.files[0]);
  }

  return (
    <div className='d-flex justify-content-center'>
      <div className="createPostContainer col-11 col-md-5 col-xxl-3 p-4 pt-3 pb-3">
        <form onSubmit={postBlog}>
          <div className='row formRow'>
            Titel:
            <input type="text" name="title" value={formData.title} onChange={handleInputChange} />
          </div>
          <div className='row formRow mt-4'>
            Innehåll:
            <textarea
              name="content"
              value={formData.content}
              onChange={handleInputChange}
              rows={5} // Set the number of visible rows
              cols={50} // Set the number of visible columns
            />
          </div>
          <div className='row formRow mt-3'>


            <div className='row'>
              <div className='col-6'>
                <p className='mb-0'>Färg</p>
                <select className='colorSelect' name="color" onChange={handleColorChange} style={{ backgroundColor: selectedColor }}>
                  <option value='#f5f0e6' style={{ backgroundColor: '#f5f0e6' }}>Beige</option>
                  <option value='#e1e6c8' style={{ backgroundColor: '#e1e6c8' }}>Ljusgrön</option>
                  <option value='#c89664' style={{ backgroundColor: '#c89664' }}>Mörkbeige</option>
                  <option value='#96321e' style={{ backgroundColor: '#96321e' }} selected>Röd</option>
                  <option value='#5a6e32' style={{ backgroundColor: '#5a6e32' }}>Grön</option>
                  <option value='#644628' style={{ backgroundColor: '#644628' }}>Brun</option>
                </select>
              </div>
              <div className='col-6'>
                <>Bild</>
                <div>
                  <label className="custom-file-upload">
                    <input type="file" name="imagePath" onChange={handleImageChange} accept="image/*" />
                    Välj bild
                  </label>
                  {uploadedFileName && (
                    <p style={{ fontSize: '12px', position: 'absolute' }}>{uploadedFileName}</p>
                  )}
                </div>
              </div>
            </div>

          </div>
          <div className='d-flex justify-content-around mt-5'>
            <button type="submit" className='mt-4'>Publicera inlägg</button>
            <button className='mt-4' onClick={hidePost}>Avbryt</button>
          </div>


        </form>
      </div>
    </div>
  );
}
