import renderPageText from '../Components/RenderPageTexts';
export default function Redovisningar(){
    renderPageText("redovisningar");
    return (<>
    <div className="redovisningarContainer">
            {/*      image row */}
            <div className="col-12">
                    <div className="row imageRow">
                        <img src='/images/HR bilder/headers/Årsredovisningar.jpg' />
                    </div>
                </div>
                <div className="col-12 renoveringar p-md-5 pt-5 p-0 pb-5">
                    <div className="ruleDownloadContainer">
                        <p style={{ paddingRight: "20px" }}>
                            <i style={{ color: "#96321e" }}><b>Trivselregler</b></i>
                            <i className="fa fa-download mt-2" style={{ float: "right" }}></i>
                        </p>
                        <div style={{ borderBottom: "2px solid black" }}></div>
                        <p className="mt-2" style={{ paddingRight: "20px" }}>
                            <i style={{ color: "black" }}>Regler vid ombyggnad/renovering</i>
                            <i className="fa fa-download mt-2" style={{ float: "right", }}></i>
                        </p>
                        <div style={{ borderBottom: "2px solid black" }}></div>
                        <p className="mt-2" style={{ paddingRight: "20px" }}>
                            <i style={{ color: "black" }}>Detaljerade regler</i>
                            <i className="fa fa-download mt-2" style={{ float: "right" }}>
                            </i>
                        </p>

                    </div>
                    <p></p>
                </div>
                <div className="row">
                    <div className="col-md-6 p-md-5 p-5 pb-0">
                        <p style={{fontSize: "28px"}}>Trivselregler</p>
                        <div className="ruleText mt-5">
                            <p className="title newTitle title1"><b></b></p>
                            <p className="bread newText text1"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title2"><b></b></p>
                            <p className="bread newText text2"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title3"><b></b></p>
                            <p className="bread newText text3">df</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title4"><b></b></p>
                            <p className="bread newText text4"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title5"><b></b></p>
                            <p className="bread newText text5"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title6"><b>t</b></p>
                            <p className="bread newText text6"></p>
                        </div>
                    </div>
                    <div className="col-md-6 p-md-5 p-5 pt-0 rules2">
                    <p style={{fontSize: "28px"}}>&nbsp;</p>
                    <div className="ruleText mt-md-5">
                            <p className="title newTitle title7"><b></b></p>
                            <p className="bread newText text7"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title8"><b></b></p>
                            <p className="bread newText text8"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title9"><b></b></p>
                            <p className="bread newText text9"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title10"><b></b></p>
                            <p className="bread newText text10"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title11"><b></b></p>
                            <p className="bread newText text11"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title12"><b></b></p>
                            <p className="bread newText text12"></p>
                        </div>
                    </div>
                </div>
    </div>
    </>)
}