import { useState } from "react";
import axios from "axios";

export default function LogOut() {
    const closeLogout = () => {
        document.getElementsByClassName("opacity")[0].style.display = "none"
        document.getElementsByClassName("logOutWindow")[0].style.display = "none"
    }
    const logOut = () => {
        localStorage.removeItem("bc7f398893fc");
        window.location.reload();
    }

    return (
        <>
            <div className='logOutWindow text-center p-3'>
               <p><b>Är du säker på att du vill logga ut?</b></p>
                <div className="container-fluid d-flex justify-content-around">
                    <button onClick={logOut}>Logga ut</button>
                    <button className="" onClick={closeLogout}>Avbryt</button>
                </div>

            </div>
            <div className='opacity'></div>
        </>
    )
}