import renderPageText from '../Components/RenderPageTexts';
export default function Maklare() {
    renderPageText("maklare")
    return (
        <>
            <div className="maklarContainer">
                {/*      image row */}
                <div className="col-12">
                    <div className="row imageRow">
                        <img src='/images/HR bilder/headers/Mäklarinfo.jpg' />
                    </div>
                </div>
                {/*  text */}
                <div className="row">
                    <div className="col-md-6 p-md-5 p-5 pt-0 pb-0">
                        <div className="ruleText mt-5">
                            <p className="title newTitle title1"><b>Allmänt</b></p>
                            <p className="bread newText text1" style={{ textAlign: "left" }}>Brf Strindbergshuset registrerades 1989 och förvärvade byggnaden
                                2003. Byggnadens totalyta är enligt taxeringsbeskedet 11 570 m²,
                                varav 8 742 m² utgör lägenhetsyta och 2 828 m² utgör lokalyta. Föreningen upplåter 96 lägenheter med bostadsrätt samt 2 lägenheter
                                och 5 lokaler med
                                hyresrätt.Föreningen har god ekonomi och har amorterat av XX
                                Mkr under 2022 och 2023.
                                <br></br>
                                <br></br>
                                <b>Adresser: </b>Karlaplan 10 och Karlavägen 82<br></br>
                                <b>Organisationsnummer: </b> 716419-2341
                                <br></br>
                                <b>Fastighetens byggnadsår: </b>1969-1971
                                <br></br>
                                <b>Antal lägenheter(bostadsrätter): </b> 98 varav 2 uthyrs
                                <br></br>
                                <b>Antal lokaler (för uthyrning): </b> 5 st
                                <br></br>
                                <b>Marken: </b>Friköpt
                                <br></br>
                               <b> Äkta/oäkta förening: </b>Äkta</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title2"><b>I avgiften ingår:</b></p>
                        </div>
                        <div className="ruleText mt-3">

                            <p className="bread newText text2">
                                <ul>
                                <li>Värme</li>
                                
                                <li> Vatten<br></br></li>
                                <li>Tvättstuga i källaren med tre tvättmaskiner, torktumlare, torkskåp
                                och mangel. Nya maskiner installerades under januari 2022</li>
                                <li>Källarförråd<br></br></li>
                                <li>Cykelförvaring på gården<br></br></li>
                                <li> Barnvagnsrum, i entrén till Karlaplan 10.<br></br></li>
                                <li>TV genom Tele2 (fd Comhem), kanaler som ingår i basutbudet</li>
                                <li>1,2,3,4,8 och kunskapskanalen<br></br></li>
                                <li>Bredband genom Ownit, 1000 Mbit/s<br></br></li>
                                <li>Det finns även ett grovsoprum och ett återvinningsrum</li>
                                <li> Uppvärmning och ventilation</li>
                                </ul>
                                </p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title3"><b>1. Skada i lägenhet</b></p>
                            <p className="newText newText text3">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title4"><b>1. Skada i lägenhet</b></p>
                            <p className="bread newText text4">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title5"><b>1. Skada i lägenhet</b></p>
                            <p className="bread newText text5">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                    </div>
                    <div className="col-md-6 p-md-5 p-5 pt-0 rules2">
                        <p style={{ fontSize: "28px" }}>&nbsp;</p>
                        <div className="ruleText mt-5">
                            <p className="title newTitle title6"><b>1. Skada i lägenhet</b></p>
                            <p className="bread newText text6">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title7"><b>1. Skada i lägenhet</b></p>
                            <p className="bread newText text7">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title8"><b>1. Skada i lägenhet</b></p>
                            <p className="bread newText text8">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title9"><b>1. Skada i lägenhet</b></p>
                            <p className="bread newText text9">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title10"><b>1. Skada i lägenhet</b></p>
                            <p className="bread newText text10">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title11"><b>1. Skada i lägenhet</b></p>
                            <p className="bread newText text11">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                    </div>
                </div>
            </div></>
    )
}