import React, { useEffect, useState } from 'react';
import axios from 'axios';
import APIEnv from "./APIEnv";
export default function RenderPage(page){
    
    useEffect(() => {
        async function fetchData() {
            let attempts = 5;
            let current = 0;
            while (attempts > current) {
                try {
                    const res = await axios.post(`${APIEnv.apiUrl}/api/pagecontent`, {
                        page: page,
                        content: "",
                        section: 0
                    });
                    const pageTexts = res.data.entries;                   
                    for (const text of pageTexts) {
                        const elementTextClass = "text" + text.section;
                        const elementTitleClass = "title" + text.section;
                        const textElement = document.getElementsByClassName(elementTextClass)[0];
                        const titleElement = document.getElementsByClassName(elementTitleClass)[0];
                        const regex = /<title>([\s\S]*?)<\/title>[\s\S]*?<text>([\s\S]*?)<\/text>/;
                        
                        const match = text.content.match(regex);
                        const titleText = match[1].trim();
                        const bodyText = match[2].trim();
    
                        if (textElement) {
                            textElement.innerHTML = bodyText;
                            textElement.setAttribute("id", text.id);
                        }
                        if (titleElement) {
                            titleElement.innerHTML = titleText;
                            titleElement.setAttribute("id", text.id);
                        }
                    }
    
                    break;
                } catch(e) {
                    console.log(e)
                    const textElement = document.getElementsByClassName("newText");
                    const titleElement = document.getElementsByClassName("newTitle");
                    for (const text of textElement) {
                        text.innerHTML = `Xxxxx xxxx xxxx. Xxxxx xxxx xxxx. Xxxxx xxxx xxxx. Xxxxx xxxx
                        xxxx. Xxxxx xxxx xxxx. Xxxxx xxxx xxxx. Xxxxx xxxx xxxx`
                    }
                    for (const title of titleElement) {
                        title.innerHTML = "Placeholder Title"
                    }
                    current++; 
                }
            }
        }
        fetchData();
    }, [])
}