export default function Footer() {

    return (
        <>
            <div className="footer">
                <div className="row p-5 pt-4 pb-3">
                    <div className="col-md-4 footerText">
                        <p className="mt-md-2">® Bostadsrättsföreningen Strindbergshuset</p>
                        <p>Karlavägen 82 / Karlaplan 10</p>
                        <p>115 22 Stockholm</p>
                    </div>
                    <div className="col-md-4 footerText">
                        <p className="mt-md-2">kontakt@strindbergshuset.se</p>
                        <p>Tel 00-000 00 00</p></div>
                    <div className="col-md-4 col-12 footerTextLast container-fluid">
                        Brf Strindbergshuset är en äkta bostadsrättsförening som bildades 2008.
                        Föreningen består av 96 lägenheter med
                        bostadsrätt samt 2 lägenheter och 5
                        lokaler med hyresrätt samt ett garage i
                        två plan</div>
                </div>
            </div>
        </>
    )
}