export default function Forum() {

    const token = localStorage.getItem("bc7f398893fc");
    if(!token){
        window.location.href ="/"
    }
    return (
        <>
        {token ? <>
            <div className="forumContainer">
                {/*      image row */}
                <div className="col-12">
                    <div className="row imageRow">
                        <img src='/images/april/Sidhuvud Anslagstavlan.jpg' />
                    </div>
                </div>
                <div className="mt-5 text-center" style={{ fontSize: "15px" }}>
                    Välkommen till föreningens anslagstavla!<br></br> Här kan du göra personliga inlägg som du delar med andra
                    inloggade medlemmar.<br></br> Inläggen är inte anonyma.
                </div>
            </div>
        </> : <></>}
        </>
    )
}