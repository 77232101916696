// config.js

let apiUrl;

if (process.env.NODE_ENV === 'development') {
  apiUrl = 'https://localhost:7181'; // Local development API endpoint
} else {
  apiUrl = 'http://onskemobler.se'; // Live API endpoint
}

module.exports = {
  apiUrl: apiUrl
};
