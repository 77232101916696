import React, { Component } from 'react';
import About from './Pages/About'
import Home from './Pages/Home'
import Contact from './Pages/Contact'
import History from './Pages/History';
import Header from './Components/NotValidHeader';
import Login from '../src/Components/Login'
import Footer from './Components/Footer'
import './Styles/index.css'
import {
  Routes,
  Route
} from "react-router-dom";
import Rules from './Pages/Rules';
import Spaces from './Pages/Spaces';
import Renoveringar from './Pages/Renoveringar';
import Maklare from './Pages/Maklare';
import Stadgar from './Pages/Stadgar';
import Redovisningar from './Pages/Redovisningar';
import Meetings from './Pages/Meetings';
import CreatePost from './Components/CreatePost';
import CreateAccount from './Components/CreateAccount';
import LogOut from './Components/LogOut';
import PopUp from './Components/PopUp';
import Profile from './Pages/Profile';
import Forum from './Pages/Forum';
import ProtectedRoute from './Components/ProtectedRoute'; // Adjust the path as needed
import VerifyUser from './Components/VerifyUser';
import NewHeader from './Components/NewHeader';

export default class App extends Component {
  
  render() {
   
    return (
      
      <>
        <NewHeader />
        <Login />
        <CreatePost />
        <CreateAccount />
        <LogOut />
        <PopUp />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/history" element={<History />} />
          <Route path="/spaces" element={<Spaces />} />
          <Route path="/renoveringar" element={<Renoveringar />} />
          <Route path="/maklare" element={<Maklare />} />
          <Route path="/stadgar" element={<Stadgar />} />
          <Route path="/redovisningar" element={<Redovisningar />} />
          <Route path="/meetings" element={<Meetings />} />
          <Route path="/forum" element={<Forum/>} />
        </Routes>
        <Footer />
      </>
    );
  }
}
