import PopUp from './PopUp';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import APIEnv from "./APIEnv";
export default function DeleteUser() {


    const [users, setUsers] = useState([]);
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const res = await axios.get(`${APIEnv.apiUrl}/user/allusers?salt=${localStorage.getItem("bc7f398893fc")}`);
                setUsers(res.data);
            } catch (e) {
                console.log(e)
            }
        };
        fetchUsers();
    }, []);


    const closePop = () => {
        const popUp = document.getElementsByClassName("deleteUserContainer")[0];
        popUp.style.opacity = "0";
        popUp.style.pointerEvents = "none";
    }

    const [deleteUser, setDeleteUser] = useState("");
    const deleteSet = (e) => {
        setDeleteUser(e.target.value);
    };

    const ultDelete = async () => {
        const popUp = document.getElementsByClassName("deleteUserContainer")[0];
        const body = {
            saltId: deleteUser,
            salt: localStorage.getItem("bc7f398893fc")
        }
        console.log(body)
        try {
            const res = await axios.post(`${APIEnv.apiUrl}/user/deleteuser`, body);
            alert("Användaren raderad!")
            popUp.style.opacity = "0";
            popUp.style.pointerEvents = "none";
        } catch (e) {
            console.log(e);
        }
    }

    return (<>
        <div className="deleteUserContainer pt-3 pb-3 d-flex flex-column align-items-center text-center">
            <select className="deleteUserSelect" onChange={deleteSet} >
                <option></option>
            {users.map((user) => {
          if (user.name !== "") {
            return (
              <option key={user.salt} value={user.salt}>
                {user.name}
              </option>
            );
          }
          return null; // You might want to handle cases where user.name is empty
        })}
            </select>
            <div className='col-10 d-flex justify-content-around'>
                <button class="newUserButton submitButton mt-3" onClick={ultDelete}>
                    Radera användare
                </button>
                <button className='newUserButton mt-3' onClick={closePop}>Avbryt</button>
            </div>
        </div>
    </>)
}