import renderPageText from '../Components/RenderPageTexts';
export default function Renoveringar() {
    renderPageText("renoveringar")
    return (
        <>
            <div className="renoveringarContainer">
                {/*      image row */}
                <div className="col-12">
                    <div className="row imageRow">
                        <img src='/images/HR bilder/headers/Renoveringar.jpg' />
                    </div>
                </div>
                <div className="row ">
                    <div className="col-12 p-md-5 p-5 pb-0">
                        <h4>Genomförda & planerade renoveringar</h4>

                    </div>
                </div>
                {/*  info */}
                <div className="row">
                    <div className="col-md-6 p-md-5 p-5 pb-0">

                        <div className="ruleText">
                            <p className="title"><b>Genomförda renoveringar</b></p>

                        </div>

                        <div className="ruleText mt-3">
                            <p className="title"><b>2003</b></p>
                            <p className="text">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                    </div>
                    <div className="col-md-6 p-md-5 p-5 pt-4">

                        <div className="ruleText">
                            <p className="title newTitle title1"><b>Planerade kommande renoveringar</b></p>

                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title1"><b>Renovering 1</b></p>
                            <p className="newText text1">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title2"><b>Renovering 1</b></p>
                            <p className="newText text2">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title3"><b>Renovering 1</b></p>
                            <p className="newText text3">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title4"><b>Renovering 1</b></p>
                            <p className="newText text4">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                xxxxx x. Xxx xxx x xxxxx xxx.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}