import renderPageText from '../Components/RenderPageTexts';
export default function Spaces() {
    renderPageText("spaces");
    return (
        <>
            <div className="spacesContainer">
                {/*      image row */}
                <div className="col-12">
                    <div className="row imageRow" style={{ marginLeft: "-50px" }}>
                        <img src='/images/HR bilder/headers/Gemensamma utrymmen.jpg' />
                    </div>
                </div>
                <div className="mt-5 text-center" style={{fontSize: "15px"}}>

                       Föreningen har flera gemensamma ytor som alla kan
                        använda i huset.<br></br> Nedan kan ni
                        läsa mer om dem.
                </div>
                <div className="row d-flex pictureRow mt-5">
                    <div className="col-md-6 sectionImage p-0 d-flex flex-column">
                        <img src='/images/april/Garaget.jpg' />
                    </div>
                    <div className="col-md-6 d-flex p-0">
                        <div className="textContainer" style={{ backgroundColor: "#96321e", color: "white" }}>
                            <h5 className="newTitle title1"></h5>
                            <p className="newText text1 mt-4 second-paragraph">
                            </p>
                        </div>
                    </div>

                </div>
                <div className="row d-flex pictureRow">

                    <div className="col-md-6 d-flex p-0">
                        <div className="textContainer">
                            <h5 className="newTitle title2"></h5>
                            <p className="newText text2 mt-4 second-paragraph">
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 sectionImage p-0 d-flex flex-column">
                        <img src='/images/april/Takterassen.jpg' />
                    </div>

                </div>
                <div className="row d-flex pictureRow">
                    <div className="col-md-6 sectionImage p-0 d-flex flex-column">
                        <img src='/images/april/Vinförråd färg.jpg' />
                    </div>
                    <div className="col-md-6 d-flex p-0">
                        <div className="textContainer" style={{ backgroundColor: "#c89664", color: "white" }}>
                            <h5 className="newTitle title3"></h5>
                            <p className="newText text3 mt-4 second-paragraph">
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row d-flex pictureRow">

                    <div className="col-md-6 d-flex p-0">
                        <div className="textContainer">
                            <h5 className="newTitle title4"></h5>
                            <p className="newText text4 mt-4 second-paragraph">
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 sectionImage p-0 d-flex flex-column">
                        <img src='/images/april/skitig hund.jpg' />
                    </div>

                </div>
                <div className="row d-flex pictureRow">
                    <div className="col-md-6 sectionImage p-0 d-flex flex-column">
                        <img src='/images/april/Tvättstuga.jpg' />
                    </div>
                    <div className="col-md-6 d-flex p-0">
                        <div className="textContainer" style={{ backgroundColor: "#c89664", color: "white" }}>
                            <h5 className="newTitle title5"></h5>
                            <p className="newText text5 mt-4 second-paragraph">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}